<template>
   <div id="page-content">
    <div class="row">
            <div class="col-lg-12">
              <div class="widjet">
            
                <div class="p-3 widjetcontent">
                 
                    <div class="row">
                      <div class="col-lg-12">
                        <div>
                          
                          <div class="tbllist mt-1">
                            <div class="restable">
                              <div class="resrow resheader">
                                <div class="cell" style="width: 10%">S.No</div>
                                <div class="cell">Subscription No</div>
                                <div class="cell">Subscription Status</div>
                                <div class="cell">Plan Name</div>
                                <div class="cell">Plan Activation Date</div>
                                <div class="cell">Plan Type</div>
                                <div class="cell">Next Billing Date</div>
                                <div class="cell">Amount</div>

                              </div>

                              <div
                                class="resrow"
                                v-for="(data, index) in subscriptions"
                                :key="index"
                              >
                               
                                <div class="cell" data-title="From Month">
                                  {{ index + 1 }}
                                </div>
                               
                                <div class="cell" data-title="From Year">
                                  <a href="javascript:void(0);" class="txtellipsis"  @click.prevent="viewSubscriptionDetails(index)">{{ data.subscription_number }}
                                 </a>
                                  
                                </div>
                                 <div class="cell" data-title="From Year" v-if="data.status != 'unpaid'">
                                  {{ data.status }} 
                                </div>
                                 <div class="cell" data-title="From Year" v-else>
                                  {{ data.status }} (
                                  <a href="javascript:void(0);"  @click.prevent="paymentNow">Pay Now
                                 </a> )
                                </div>
                                
                                <div class="cell" data-title="From Month">
                                  {{ data.plan_name }}
                                </div>
                                <div class="cell" data-title="From Month">
                                  {{ data.activated_at }}
                                </div>
                                <div class="cell" data-title="From Year">
                                  {{ data.interval + " " + data.interval_unit }}
                                </div>
                                <div class="cell" data-title="From Year">
                                  {{ data.next_billing_at }}
                                </div>
                                <div class="cell" data-title="From Year">
                                  {{ data.amount }}
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 
                </div>
                <div class="widjetfooter text-right">
                  <!-- <span class="required"
                        >* Note: You can email us at adminuser@vidhyaan.com or
                        call us at for any clarifications.
                      </span> -->
                  <div class="dk_iconsml">
                    <span class="required" style="margin-right: 159px;"
                        >* Note: Login into Zoho Billing to View your Invoice and Subscription plan.
                      </span>
              <button type="button" class="btn btnsml" @click.prevent="redirectRoute('/subscription/plan/upgrade')">
                 <span>Upgrade</span>
              </button>
              <button type="button" class="btn btnsml" style="width: 138px;" @click.prevent="navigateToBilling">
               Zoho Billing
              </button>
             
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
  </div>
   <b-modal id="showSubscriptionPopUp" no-close-on-backdrop no-close-on-esc>
    <button type="button" class="close"  @click.prevent="closePoup">×</button>
    
        <div class="contact-form">
          <h2> Subscription Details</h2>
          <br />
          <div>
            <form
              data-vv-scope="teacherValidate"
              id="addteacher"
              accept-charset="utf-8"
            >
              <div class="form-row">
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="teacherName"
                      class="form-control form-input"
                      id="tchrnameid"
                      disabled
                      v-model="planDetails.customer_id"
                    />
                   
                  <label for="tchrnameid" 
                    >Customer Id </label
                  >

                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="planDetails.plan">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="teacherName"
                      class="form-control form-input"
                      id="tchrnameid"
                      disabled
                      v-model="planDetails.plan.name"
                    />
                   
                  <label for="tchrnameid" 
                    >Plan Name </label
                  >

                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="teacherName"
                      class="form-control form-input"
                      id="tchrnameid"
                      disabled
                      v-model="planDetails.subscription_id"
                    />
                   
                  <label for="tchrnameid" 
                    >Subscription Id </label
                  >

                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="teacherName"
                      class="form-control form-input"
                      id="tchrnameid"
                      disabled
                      v-model="planDetails.status"
                    />
                   
                  <label for="tchrnameid" 
                    >Status </label
                  >

                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="planDetails.status == 'live'">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="teacherName"
                      class="form-control form-input"
                      id="tchrnameid"
                      disabled
                      v-model="planDetails.activated_at"
                    />
                   
                  <label for="tchrnameid" 
                    >Plan Activation Date </label
                  >

                  </span>
                </div>
                 <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="planDetails.status == 'cancelled'">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="teacherName"
                      class="form-control form-input"
                      id="tchrnameid"
                      disabled
                      v-model="planDetails.cancelled_at"
                    />
                   
                  <label for="tchrnameid" 
                    >Plan Cancelled Date </label
                  >

                  </span>
                </div>
                 <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="teacherName"
                      class="form-control form-input"
                      id="tchrnameid"
                      disabled
                      v-model="planDetails.current_term_starts_at"
                    />
                   
                  <label for="tchrnameid" 
                    >Plan start date </label
                  >

                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="teacherName"
                      class="form-control form-input"
                      id="tchrnameid"
                      disabled
                      v-model="planDetails.current_term_ends_at"
                    />
                   
                  <label for="tchrnameid" 
                    >Plan end date </label
                  >

                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="teacherName"
                      class="form-control form-input"
                      id="tchrnameid"
                      disabled
                      v-model="planDetails.shipping_interval_unit"
                    />
                   
                  <label for="tchrnameid" 
                    >Interval unit </label
                  >

                  </span>
                </div>
                
                <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="planDetails.status == 'live'">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="teacherName"
                      class="form-control form-input"
                      id="tchrnameid"
                      disabled
                      v-model="planDetails.plan.quantity"
                     
                    />
                    <!-- :value="currentPlanQuantityDetails" -->
                  <label for="tchrnameid" 
                    >Quantity </label
                  >

                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="planDetails.status == 'live'">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="teacherName"
                      class="form-control form-input"
                      id="tchrnameid"
                      disabled
                      :value="planNoOfStudentDetails(planDetails)"
                    />

                  <label for="tchrnameid" 
                    >Total no of students </label
                  >

                  </span>
                </div>
                 <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="planDetails.status == 'live'">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="teacherName"
                      class="form-control form-input"
                      id="tchrnameid"
                      disabled
                      v-model="createdStudent"
                    />
                   
                  <label for="tchrnameid" 
                    >No of created students </label
                  >

                  </span>
                </div>
                 <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="planDetails.status == 'live'">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="teacherName"
                      class="form-control form-input"
                      id="tchrnameid"
                      disabled
                      :value="remainingStudent(planDetails)"

                    />
                   
                  <label for="tchrnameid" 
                    >No of remaining student </label
                  >

                  </span>
                </div>
                
                
              </div>
              <div
                class="dispflex mt-3 pt-3"
                style="border-top: 1px solid #e5e5e5"
              >
                <div class="dk_icon">
                
                </div>
                <div class="text-right dk_iconsml">
                  <button type="button" @click.prevent="closePoup" class="btn btncl clsmdl" id="clbtn">
                       <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
         
        </div>
    </b-modal>
  </div>
</template>
<script>

import ViService from "@/services/ViService";
import secureUI from "../utils/secureUI";


export default {
  name: "SUBSCRIPTION",
 
  data() {
    return {
      subscriptions: [],
      invoiceDetails: [],
      planDetails: {},
      totalStudent: 0,
      createdStudent: 0,
      reType: 'Auto-Renewal'
    };
  },
  created() {
    this.getPlanAndSubscriptionDetails();
  },
  computed: {
    currentPlanDetails() {
      const details = this.$store.getters.currentPlanDetail;
      
       if(details){
         const studentsCount = details.plan.item_custom_fields.filter( x => x.placeholder == 'cf_no_of_students')
         if(studentsCount.length > 0){
          return studentsCount[0].value * details.plan.quantity
         }else{
           return 0
         }
       }else{
        return 0
       }
    },
    currentPlanQuantityDetails() {
      const details = this.$store.getters.currentPlanDetail;
      
       if(details){
        return details.plan.quantity || 1
        //  if(studentsCount.length > 0){
        //   return studentsCount[0].value
        //  }else{
        //    return 0
        //  }
       }else{
        return 1
       }
    },
  },
  
  methods: {
    remainingStudent(details) {
      if(details){
         const studentsCount = details.plan.item_custom_fields.filter( x => x.placeholder == 'cf_no_of_students')
         if(studentsCount.length > 0){
          let noOfStudents = studentsCount[0].value * details.plan.quantity
          return noOfStudents - this.createdStudent;
         }else{
           return 0
         }
       }else{
        return 0
       }
          
    },
    planNoOfStudentDetails(details) {
     // const details = this.$store.getters.currentPlanDetail;
      
       if(details){
         const studentsCount = details.plan.item_custom_fields.filter( x => x.placeholder == 'cf_no_of_students')
         if(studentsCount.length > 0){
          return studentsCount[0].value * details.plan.quantity
         }else{
           return 0
         }
       }else{
        return 0
       }
    },
    
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
      
    },
    navigateToBilling(){
      window.open('https://billing.zoho.in/portal/vidhyaantest/login')

    },
    async viewSubscriptionDetails(index){
      const details = this.subscriptions[index]
      
    
      await this.getSubscriptionDetails(details.subscription_id)
      //this.$bvModal.show("showSubscriptionPopUp");
    },
    closePoup(){
      this.$bvModal.hide("showSubscriptionPopUp");
    },
    paymentNow(){
        window.open('https://subscriptions.zoho.in/portal/vidhyaan')
    },
    async getPlanAndSubscriptionDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet("/account/getPlanDetails?accountId=" + userData.userDetails.account._id,userData.token);
        
        if (response.isSuccess) {
          
          this.subscriptions = response.data.subscriptions || []
          this.invoiceDetails = response.data.invoiceDetails || []
          this.createdStudent = response.data.createdStudentsCount || 0
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    async getSubscriptionDetails(id) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet("/account/getSubscriptionDetails?subscriptionId=" + id,userData.token);
        
        if (response.isSuccess) {
          this.planDetails = response.data.subscription

          this.$bvModal.show("showSubscriptionPopUp");
          // this.subscriptions = response.data.subscriptions || []
          // this.invoiceDetails = response.data.invoiceDetails || []
          // this.createdStudent = response.data.createdStudentsCount || 0
        } else {
          this.$toasted.error(response.message);
        }
      }
    }
  },
  
};
</script>